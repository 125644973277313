/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import {
  fetchUserAddressStart,
  saveUserAddressStart,
} from "../../store/actions/UserAction";
import reviewstar from "../../assets/images/review-star.svg";
import fk500 from "../../assets/images/fk500.png";
import fk1000 from "../../assets/images/fk1000.png";
import fk2000 from "../../assets/images/fk2000.png";
import ApiMethods from "../../utils/ApiMethods";
import { createNotification } from "react-redux-notify";
import {
  getErrorNotificationMessage,
  getSuccessNotificationMessage,
} from "./NotificationMessage";
import {
  removeItem,
  addItem,
  quantityDecrease,
  isMember,
  isLogin,
  isUserLogin
} from "../../utils";
import Login from "../Login/Login";
import cartImage from "../../assets/images/cart-image.jpg";
import appendSpreadsheet from "./appendSpreadsheet";

const initialState = {
  fullName: "",
  mobile_Number: "",
  pincode: "",
  address: "",
  town: "",
  district: "",
  state: "",
};

const Cart = (props) => {
  const membershipPrice = 300;
  const deliveryCharge = 49;
  const [couponPrice, setCouponPrice] = useState(0);
  const [selectCoupon, setSelectCoupon] = useState("");
  const [openLogin, setOpenLogin] = useState(false);
  let coatingPrice;
  let totalPrice;
  let lensTotal;
  let framePrice;
  let _coatingPrice;
  let _totalPrice;
  let _lensTotal;
  let _framePrice;
  let membershipBenefit = 0;
  let cartItems = 0;
  const [
    { fullName, mobile_Number, pincode, address, town, district, state },
    setAddressData,
  ] = useState(initialState);
  const [eliteMember, setEliteMember] = useState(false);
  const [loyalityPrice, setLoyalityPrice] = useState(0);
  const [loyalityPoints, setLoyalityPoints] = useState("");
  const [addAddress, setAddAddress] = useState(false);
  const [selectAddress, setSelectAddress] = useState("");
  const [phoneError, setPhoneError] = useState("");
  const [frameProtectionAdded, setFramProtectionAdded] = useState(false);
  const cartData = JSON.parse(sessionStorage.getItem("products")) || [];
  const { data: addressInfo } = props.userAdd.data;
  const isMembershipAdded = isMember() || sessionStorage.getItem("membership");
  const shipAddress =
    addressInfo && addressInfo.filter((add) => add._id === selectAddress);
  if (cartData.length > 0) {
    cartItems = cartData.reduce((acc, curr) => {
      return (acc = acc + curr.qty);
    }, 0);
  }

  // Default coupons
  const defaultCoupons = [
    {
      title: "GET\n UPTO 500 OFF\n ON FIRST ORDER",
      code: "Fk 500",
      image: fk500,
    },
    {
      title: "GET\n UPTO 500 OFF\n ON FIRST ORDER",
      code: "Fk 1000",
      image: fk1000,
    },
    {
      title: "GET\n UPTO 500 OFF\n ON FIRST ORDER",
      code: "Fk 2000",
      image: fk2000,
    },
  ];


  cartData.forEach((element) => {
    // Frame Price
    if (
      element.brand === "FK frames" &&
      !element?.onlyFrame &&
      element?.productType === "Inhouse" &&
      element?.discount_price === 599
    ) {
      element.productPrice = 0;
    } else {
      element._productPrice = element.discount_price * element.qty;
    }
    // lens charge
    if (element?.lensPrice) {
      element._lensCharge = element.lensPrice * element.qty;
      // coating charge
      if (element?.isCoatingApplied) {
        element._coatingCharge = 1000 * element.qty;
      }
    }
  });
  // Total price
  cartData.forEach((element) => {
    // Frame Price
    const isEven = element.qty % 2 == 0 ? true : false;
    const quantity = isEven ? element.qty / 2 : (element.qty - 1) / 2;
    if (
      element.brand === "FK frames" &&
      !element?.onlyFrame &&
      element?.productType === "Inhouse" &&
      element?.discount_price === 599
    ) {
      element.productPrice = 0;
    } else {
      element.productPrice =
        isMembershipAdded &&
          element?.productType === "Inhouse" &&
          element.qty > 1 &&
          !(
            element.category === "Accessories" ||
            element.category === "Contact Lens"
          )
          ? element.discount_price * (element.qty - quantity)
          : element.discount_price * element.qty;
    }
    // lens charge
    if (element?.lensPrice) {
      element.lensCharge =
        isMembershipAdded &&
          element?.productType === "Inhouse" &&
          element.qty > 1 &&
          !(
            element.category === "Accessories" ||
            element.category === "Contact Lens"
          )
          ? element.lensPrice * (element.qty - quantity)
          : element.lensPrice * element.qty;
      // coating charge
      if (element?.isCoatingApplied && element?.productType === "Inhouse") {
        element.coatingCharge =
          isMembershipAdded &&
            element.qty > 1 &&
            !(
              element.category === "Accessories" ||
              element.category === "Contact Lens"
            )
            ? 1000 * (element.qty - quantity)
            : 1000 * element.qty;
      }
    }
  });

  coatingPrice = cartData.reduce((acc, curr) => {
    if (curr?.coatingCharge > 0) {
      acc = acc + curr?.coatingCharge;
    }
    return acc;
  }, 0);

  lensTotal = cartData.reduce((acc, curr) => {
    if (curr?.lensCharge > 0) {
      acc = acc + curr?.lensCharge;
    }
    return acc;
  }, 0);

  framePrice = cartData.reduce((acc, curr) => {
    // if (curr?.productPrice > 0) {
    acc = acc + curr?.productPrice;
    // }
    return acc;
  }, 0);

  _coatingPrice = cartData.reduce((acc, curr) => {
    if (curr?._coatingCharge > 0) {
      acc = acc + curr?._coatingCharge;
    }
    return acc;
  }, 0);

  _lensTotal = cartData.reduce((acc, curr) => {
    if (curr?._lensCharge > 0) {
      acc = acc + curr?._lensCharge;
    }
    return acc;
  }, 0);

  _framePrice = cartData.reduce((acc, curr) => {
    if (curr?.productPrice > 0) {
      acc = acc + curr?._productPrice;
    }
    return acc;
  }, 0);

  _totalPrice = _coatingPrice + _lensTotal + _framePrice;

  let lensData = cartData.reduce((acc, curr) => {

    let coating = curr?.coatingCharge > 0 ? curr.coatingCharge : 0;
    let lens = curr?.lensCharge > 0 ? curr.lensCharge : 0;
    let total = curr.productPrice + lens + coating;

    let obj = {
      product: curr._id,
      quantity: curr.qty.toString(),
      price: curr?.discount_price.toString(),
      color: curr.color[0].color,
      total: total.toString(),
      images: curr.color[0].images,
      Coating: coating > 0 ? true : false,
      AddedLens: lens > 0 ? true : false,
      MembershipAdded: eliteMember ? true : false,
      lensType: curr.onlyFrame,
      powerType: curr.powerType

    };
    acc.push(obj);
    return acc;
  }, []);

  let taxCalculation = cartData.reduce((acc, curr) => {
    let coating = curr?.coatingCharge > 0 ? curr.coatingCharge : 0;
    let lens = curr?.lensCharge > 0 ? curr.lensCharge : 0;
    let total = curr.productPrice + lens + coating;
    let tax;
    if (curr.category === "Eyeglasses") {
      tax = (total / 100) * 12;
    } else if (curr.category === "Sunglasses") {
      tax = (total / 100) * 18;
    } else if (curr.category === "Computer Glasses") {
      tax = (total / 100) * 28;
    } else if (curr.category === "Contact Lens") {
      tax = (total / 100) * 12;
    } else if (curr.category === "Accessories") {
      tax = (total / 100) * 18;
    }
    acc = acc + tax;
    return acc;
  }, 0);

  let totalTax = isMembershipAdded ? taxCalculation : taxCalculation;

  if (isMembershipAdded && cartData.length > 1) {
    cartData.map((data) => {
      let pPrice = data.discount_price;
      let lPrice = data?.lensPrice > 0 ? data?.lensPrice : 0;
      let cPrice = data?.isCoatingApplied ? 1000 : 0;
      let total = pPrice + lPrice + cPrice;
      data.pPrice = pPrice;
      data.lPrice = lPrice;
      data.cPrice = cPrice;
      data.singleItemPrice = total;
      return data;
    });

    // if (cartData.find((data) => !data.onlyFrame)
    //   && cartData.find((data) => data.category === 'Sunglasses')) {
    //   const freeSunglass = cartData.find((data) => data.category === 'Sunglasses');
    //   coatingPrice = coatingPrice - freeSunglass.cPrice;
    //   lensTotal = lensTotal - freeSunglass.lPrice;
    //   framePrice = framePrice - freeSunglass.pPrice;
    // }

    // const skipAccessories = cartData.filter(
    //   (data) => data.category !== "Accessories"
    // );

    // leastPriceItem = skipAccessories.reduce((prev, curr) => {
    //   return prev.singleItemPrice < curr.singleItemPrice ? prev : curr;
    // });

    // coatingPrice = coatingPrice - leastPriceItem.cPrice;
    // lensTotal = lensTotal - leastPriceItem.lPrice;
    // framePrice = framePrice - leastPriceItem.pPrice;

    totalPrice = coatingPrice + lensTotal + framePrice - couponPrice;
  } else {
    totalPrice = isMembershipAdded
      ? coatingPrice + lensTotal + framePrice - couponPrice
      : coatingPrice + lensTotal + framePrice - couponPrice;
  }

  const handleAddressChange = (event) => {
    const { name, value } = event.currentTarget;
    setAddressData((prevState) => ({ ...prevState, [name]: value }));
  };

  const clearForm = () => {
    setAddressData({ ...initialState });
  };

  const handleAddressSubmit = (event) => {
    event && event.preventDefault();

    if (phoneError) {
      return;
    }
    props.dispatch(
      saveUserAddressStart({
        fullName: fullName,
        mobile_Number: mobile_Number,
        pincode: pincode,
        address: address,
        town: town,
        district: district,
        state: state,
      })
    );
    clearForm();
    setAddAddress(false);
  };

  const handleCouponSubmit = (e) => {
    e && e.preventDefault();

    // Handle coupon here
  };

  const onDefaultCouponClick = (code) => {
    // handle default coupon click
    if (code === "Fk 500") {
      if (totalPrice > 3500) {
        setCouponPrice(500);
        setSelectCoupon(code);
      } else {
        const notificationMessage = getErrorNotificationMessage(
          "Coupon applicable on shopping above 3500"
        );
        props.dispatch(createNotification(notificationMessage));
        return;
      }
    }
    if (code === "Fk 1000") {
      if (totalPrice > 6500) {
        setCouponPrice(1000);
        setSelectCoupon(code);
      } else {
        const notificationMessage = getErrorNotificationMessage(
          "Coupon applicable on shopping above 6500"
        );
        props.dispatch(createNotification(notificationMessage));
        return;
      }
    }
    if (code === "Fk 2000") {
      if (totalPrice > 10000) {
        setCouponPrice(2000);
        setSelectCoupon(code);
      } else {
        const notificationMessage = getErrorNotificationMessage(
          "Coupon applicable on shopping above 10,000"
        );
        props.dispatch(createNotification(notificationMessage));
        return;
      }
    }
  };

  useEffect(() => {
    if (isLogin()) {
      props.dispatch(
        fetchUserAddressStart({ userId: sessionStorage.getItem("user_id") })
      );
    }
  }, [props.saveAddress.loading]);

  useEffect(() => {
    async function fetchLoyalityPrice() {
      const result = await ApiMethods.getMethod("user/point/money");
      if (result?.data.success) {
        setLoyalityPrice(result.data.ruppee);
      }
    }
    if (isLogin()) {
      fetchLoyalityPrice();
    }
  }, []);

  const paymentHandler = async (e) => {
    e.preventDefault();
    const re = /^[0-9\b]+$/;
    if (loyalityPoints && parseInt(loyalityPoints) > 0) {
      if (
        parseInt(loyalityPoints) >
        parseInt(sessionStorage.getItem("loyalityPoint"))
      ) {
        alert(
          `You have Only ${sessionStorage.getItem("loyalityPoint")} Points`
        );
        return;
      }
    } else if (loyalityPoints && !re.test(loyalityPoints)) {
      alert("Please Enter Number");
      return;
    }
    if (!shipAddress.length > 0) {
      const notificationMessage = getErrorNotificationMessage(
        "Please Select Address"
      );
      props.dispatch(createNotification(notificationMessage));
      return;
    }

    if (isMember() && parseInt(loyalityPoints) > 0) {
      totalPrice = totalPrice - loyalityPrice * loyalityPoints;
    }

    const response = await ApiMethods.postMethod("user/razor/order", {
      amount: totalPrice.toString(),
    });
    const { data } = response;

    const options = {
      key: process.env.REACT_APP_RAZORPAY,
      // key: 'rzp_test_euDMQDvp43A8QQ',
      name: "Chashmaplanet.com",
      description: "",
      order_id: data.id,
      handler: async (response) => {
        try {
          const captureResponse = await ApiMethods.postMethod(
            `user/shop/order`,
            {
              Amount: totalPrice.toString(),
              ShippingAddress: {
                Address: shipAddress[0].address,
                City: shipAddress[0].district,
                Pincode: shipAddress[0].pincode.toString(),
                state: shipAddress[0].state,
                Town: shipAddress[0].town,
              },
              orderedLens: [...lensData],
              payment: {
                razorpay_order_id: response.razorpay_order_id,
                razorpay_payment_id: response.razorpay_payment_id,
                razorpay_signature: response.razorpay_signature,
              },
            }
          );
          const result = await ApiMethods.postMethod("user/point/deduct", {
            usedPoints: loyalityPoints,
          });
          if (captureResponse?.data.success) {

            if (isUserLogin()) {
              await appendSpreadsheet({
                email: sessionStorage.getItem("email"),
                event: 'CREATED_ORDER',
                createdAt: new Date().toString(),
                productId: ''
              });
            }
            const notificationMessage = getSuccessNotificationMessage(
              captureResponse.data.messgae
            );
            props.dispatch(createNotification(notificationMessage));
            sessionStorage.removeItem("products");
            props.history.push("/");
          } else {
            const notificationMessage = getErrorNotificationMessage(
              // captureResponse.data.messgae
              "Something went wrong"
            );
            props.dispatch(createNotification(notificationMessage));
          }
        } catch (err) {
          console.log(err);
        }
      },
      theme: {
        color: "#686CFD",
      },
    };

    const rzp1 = new window.Razorpay(options);
    rzp1.open();

  };

  const handleOnAddressSelect = (id) => {
    setSelectAddress(id);
  };

  const handleLoyality = (event) => {
    setLoyalityPoints(event.target.value);
  };

  if (_totalPrice > 0 && _totalPrice > totalPrice && isMembershipAdded) {
    membershipBenefit = _totalPrice - totalPrice;
  }

  if (eliteMember) {
    totalPrice = totalPrice + membershipPrice;
  }

  return (
    <div className="cart-page">
      <div className="product-detail">
        <div className="cart-left">
          <div id="cart-accordion" className="accordion">
            <div className="card">
              <div className="card-header p-0" id="bagheadng">
                <h5 className="mb-0">
                  <button
                    className="btn btn-link"
                    data-toggle="collapse"
                    data-target="#collapsebag"
                    aria-expanded="true"
                    aria-controls="collapsebag"
                  >
                    Bag
                  </button>
                </h5>
              </div>

              <div
                id="collapsebag"
                className="collapse show"
                aria-labelledby="bagheadng"
              >
                <div className="card-body">
                  {cartData.length > 0 ? (
                    cartData.map((data) => (
                      <>
                        <div className="cart-row">
                          <div className="pro-img">
                            <img src={data.imgCollection[0]} alt="" />
                          </div>
                          <div className="pro-detail-block">
                            <div className="eql-pro borderBottom">
                              <div className="pro-left">
                                <h2>{data.name}</h2>
                                <h6>
                                  {data.material +
                                    " " +
                                    data.type +
                                    " " +
                                    data.shape +
                                    " " +
                                    data.category}
                                </h6>
                                <p className="rating-text">
                                  5.0 Rating <img src={reviewstar} alt="" />
                                </p>
                              </div>
                              <div className="pro-right">
                                {data.brand === "FK frames" &&
                                  data.discount_price === 599 ? (
                                  <p>
                                    <span className="cart-price">
                                      ₹{data.discount_price}
                                    </span>
                                    <span className="blkclor">₹{0} </span>{" "}
                                  </p>
                                ) : (
                                  <p>
                                    <span className="blkclor">
                                      ₹{data.discount_price}{" "}
                                    </span>{" "}
                                    <span className="cart-price">
                                      ₹{data.price}
                                    </span>
                                  </p>
                                )}
                                <div
                                  className="blck-qty"
                                  style={{
                                    display: "flex",
                                    justifyContent: "end",
                                  }}
                                >
                                  <span className="qty-txt"> QTY </span>
                                  <a
                                    style={{ cursor: "pointer" }}
                                    onClick={() => {
                                      if (data.qty > 1) {
                                        quantityDecrease(data);
                                        window.location.reload();
                                      } else {
                                        removeItem(data.client_id);
                                        window.location.reload();
                                      }
                                    }}
                                    className="qty-sign"
                                  >
                                    {" "}
                                    &minus;{" "}
                                  </a>
                                  <input
                                    readOnly=""
                                    aria-label="item quantity"
                                    className="qty-value"
                                    type="text"
                                    value={data.qty}
                                  />
                                  <a
                                    style={{ cursor: "pointer" }}
                                    onClick={() => {
                                      if (data.qty >= parseInt(data.quantity)) {
                                        const notificationMessage =
                                          getErrorNotificationMessage(
                                            "This Product is out of Stock."
                                          );
                                        props.dispatch(
                                          createNotification(
                                            notificationMessage
                                          )
                                        );
                                        return;
                                      } else {
                                        addItem(data);
                                        window.location.reload();
                                      }
                                    }}
                                    className="qty-sign"
                                  >
                                    {" "}
                                    +{" "}
                                  </a>
                                </div>
                                <span className="blkclor">
                                  {data?.lensPrice > 0
                                    ? `Lens Package: ₹${data.lensPrice}`
                                    : null}
                                </span>
                              </div>
                            </div>
                            <div className="eql-pro">
                              <div className="size-block"></div>
                              <a
                                href=""
                                onClick={() => removeItem(data.client_id)}
                                className="remove-text"
                              >
                                Remove
                              </a>
                            </div>
                          </div>
                        </div>
                      </>
                    ))
                  ) : (
                    <div style={{ marginLeft: "0px", marginTop: "20px" }}>
                      <h4 className="text-center">
                      Your cart is empty!  <a href="/product-list/Eyeglasses">Click here</a> to add products...{" "}
                       
                      </h4>
                    </div>
                  )}
                  {cartData.length > 0 && !isLogin() && (
                    <div className="btn-row d-flex justify-content-end">
                      <a
                        onClick={() => {
                          if (!isLogin()) {
                            setOpenLogin(true);
                          }
                        }}
                        href=""
                        className="nxt-btn"
                        data-toggle="collapse"
                        data-target="#collapseAddress"
                        aria-expanded="true"
                        aria-controls="collapseAddress"
                      >
                        Next
                      </a>
                    </div>
                  )}
                </div>
              </div>
            </div>
            {/* address info */}
            {isLogin() && (
              <>
                <div className="card">
                  <div className="card-header p-0" id="addressheading">
                    <h5 className="mb-0">
                      <button
                        className="btn btn-link"
                        data-toggle="collapse"
                        data-target="#collapseAddress"
                        aria-expanded="true"
                        aria-controls="collapseAddress"
                      >
                        Address
                      </button>
                    </h5>
                  </div>
                  <div
                    id="collapseAddress"
                    className="collapse"
                    aria-labelledby="addressheading"
                  >
                    <div className="card-body">
                      <div className="saved-address-wrap">
                        <h4>Saved Addresses</h4>
                        {addressInfo?.length > 0 &&
                          addressInfo.map((address) => (
                            <p key={address._id}>
                              <span>{address.fullName}</span>{" "}
                              <span>
                                {address.address +
                                  ", " +
                                  address.town +
                                  ", " +
                                  address.district}
                              </span>
                              <span>
                                {address.state + ", " + address.pincode}
                              </span>
                              <input
                                type="checkbox"
                                style={{
                                  float: "right",
                                  height: "25px",
                                  width: "25px",
                                }}
                                value={selectAddress}
                                onChange={() =>
                                  handleOnAddressSelect(address._id)
                                }
                                checked={selectAddress === address._id}
                              />
                            </p>
                          ))}
                      </div>
                      {!addAddress && (
                        <div className="add-address">
                          <a
                            // href="#"
                            onClick={() => setAddAddress(true)}
                            className="new-add"
                          >
                            Add New Address <i className="fas fa-plus"></i>
                          </a>
                        </div>
                      )}
                      {addAddress && (
                        <form
                          onSubmit={handleAddressSubmit}
                          className="form-custom address-form"
                        >
                          <h4>Contact Details</h4>
                          
                          <div className="form-group">
                            <input
                              type="text"
                              className="form-control"
                              placeholder="Full name"
                              name="fullName"
                              required
                              onChange={handleAddressChange}
                              value={fullName}
                            />
                          </div>
                          <div className="form-group">
                            <input
                              type="text"
                              className="form-control"
                              placeholder="Mobile Number"
                              name="mobile_Number"
                              maxLength="10"
                              pattern="[0-9]{10}"
                              title="Please enter exactly 10 digits"
                              required
                              onBlur={(event) => {
                                const phoneNumber = event.currentTarget.value;
                                if (!/^[0-9]{10}$/.test(phoneNumber)) {
                                  setPhoneError("Please enter a valid 10-digit phone number without any special characters.");
                                } else {
                                  setPhoneError("");
                                }
                              }}
                              onChange={handleAddressChange}
                              value={mobile_Number}
                            />
                            {phoneError && <p className="error-message" style={{ color: 'red' }}>{phoneError}</p>}

                            {/* <input
                              type="number"
                              className="form-control"
                              placeholder="Mobile Number"
                              name="mobile_Number"
                              maxLength="10"
                              pattern="\d{10}"
                              title="Please enter exactly 10 digits"
                              required
                              onChange={handleAddressChange}
                              value={mobile_Number}
                            /> */}
                          </div>
                          <h4>Address</h4>
                          <div className="form-group">
                            <input
                              type="text"
                              className="form-control"
                              placeholder="Enter Address"
                              name="address"
                              required
                              onChange={handleAddressChange}
                              value={address}
                            />
                          </div>
                          <div className="form-group">
                            <input
                              type="text"
                              className="form-control"
                              placeholder="Locality / Town"
                              name="town"
                              required
                              onChange={handleAddressChange}
                              value={town}
                            />
                          </div>
                          <div className="form-group">
                            <input
                              type="text"
                              className="form-control"
                              placeholder="Pincode"
                              name="pincode"
                              pattern="^[1-9][0-9]{5}$"
                              title="Please enter six digit pin code and not starting with 0"
                              required
                              onBlur={async (event) => {
                                const res = await fetch(`https://api.postalpincode.in/pincode/${event.currentTarget.value}`)
                                  .then((res) => res.json());
                                if (res[0].Status === "Success") {
                                  setAddressData((prevState) => ({
                                    ...prevState,
                                    pincode: res[0].PostOffice[0].Pincode,
                                    district: res[0].PostOffice[0].District,
                                    state: res[0].PostOffice[0].State,
                                  }));
                                }
                              }}
                              onChange={handleAddressChange}
                              value={pincode}
                            />
                          </div>
                          <div className="row">
                            <div className="col-md-6">
                              <div className="form-group">
                                <input
                                  type="text"
                                  className="form-control"
                                  placeholder="District"
                                  name="district"
                                  required
                                  onChange={handleAddressChange}
                                  value={district}
                                />
                              </div>
                            </div>
                            <div className="col-md-6">
                              <div className="form-group">
                                <input
                                  type="text"
                                  className="form-control"
                                  placeholder="State"
                                  name="state"
                                  required
                                  onChange={handleAddressChange}
                                  value={state}
                                />
                              </div>
                            </div>
                          </div>

                          <div className="col-md-12">
                            <button
                              style={{ float: "right" }}
                              type="submit"
                              className="btn btn-primary custom-border-btn"
                            >
                              Save
                            </button>
                            <button
                            type="button"
                            onClick={() => setAddAddress(false)}
                           className="btn btn-primary custom-border-btn"
                            style={{ float: 'right' }}
                          >
                            Exit
                          </button>
                          </div>
                        </form>
                      )}
                    </div>
                  </div>
                </div>

                {/* payment info */}
                <div className="card">
                  <div className="card-header p-0" id="paymentheading">
                    <h5 className="mb-0">
                      <button
                        className="btn btn-link"
                        data-toggle="collapse"
                        data-target="#collapsePayment"
                        aria-expanded="true"
                        aria-controls="collapsePayment"
                      >
                        Payment
                      </button>
                    </h5>
                  </div>
                  <div
                    id="collapsePayment"
                    className="collapse"
                    aria-labelledby="paymentheading"
                  >
                    {cartData.length > 0 && (
                      <div className="card-body">
                        <div
                          style={{ marginTop: "20px" }}
                          className="col-md-12"
                        >
                          <button
                            style={{ float: "right" }}
                            type="submit"
                            className="btn btn-primary custom-border-btn"
                            onClick={paymentHandler}
                          >
                            Pay Now
                          </button>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </>
            )}
          </div>
        </div>

        <div className="cart-right py-4 pl-lg-1 pr-lg-4">
          {cartData.length > 0 ? (
            <>
              <div className="pro-list-innerblock">
                <div>
                  {isLogin() && !isMember() && (
                    <div className="custom-control custom-checkbox">
                      <input
                        id="elite-protection"
                        type="checkbox"
                        className="custom-control-input"
                        checked={
                          sessionStorage.getItem("membership") || eliteMember
                        }
                        value={
                          sessionStorage.getItem("membership") || eliteMember
                        }
                        onClick={(e) => {
                          if (e.target.checked) {
                            sessionStorage.setItem("membership", true);
                            setEliteMember(e.target.checked);
                          } else {
                            sessionStorage.removeItem("membership");
                            setEliteMember(false);
                          }
                        }}
                      />
                      <label
                        htmlFor="frame-protection"
                        className="custom-control-label"
                      >
                        <div>
                          <h5>Elite membership users can get more benefits.</h5>
                        </div>
                      </label>
                    </div>
                  )}
                </div>
                <h4>
                  Product Details <span>({cartItems} Item) </span>
                </h4>
                {cartData.length > 0 && (
                  <p>
                    Total Product Price <span>₹{framePrice}</span>{" "}
                  </p>
                )}
                {coatingPrice > 0 ? (
                  <p>
                    Total Coating Price <span>₹{coatingPrice}</span>{" "}
                  </p>
                ) : null}
                {lensTotal > 0 ? (
                  <p>
                    Total Optical Lens Price <span>₹{lensTotal}</span>{" "}
                  </p>
                ) : null}
                {eliteMember || sessionStorage.getItem("membership") ? (
                  <p>
                    Membership Price <span>₹{membershipPrice}</span>{" "}
                  </p>
                ) : null}
                {frameProtectionAdded ||
                  sessionStorage.getItem("frameprotection") ? (
                  <p>
                    Frame Protection <span>₹{1}</span>{" "}
                  </p>
                ) : null}
                {!isMember() ? (
                  <p>
                    Delievery Charges{" "}
                    <div>
                      <span className="charges">₹{deliveryCharge}</span>{" "}
                      <span>free</span>
                    </div>
                  </p>
                ) : (
                  <p>
                    Delievery Charges <span className="charges">Free</span>{" "}
                  </p>
                )}
                {isMember() ? (
                  <div
                    style={{ display: "flex", justifyContent: "space-between" }}
                  >
                    <p>Loyality Point </p>
                    <input
                      style={{ width: "70px" }}
                      type="text"
                      pattern="\d{10}"
                      title="Please Enter Number Only."
                      onChange={(event) => handleLoyality(event)}
                    />
                  </div>
                ) : null}
                {couponPrice > 0 ? (
                  <p>
                    Coupon Discount <span>-₹{couponPrice}</span>{" "}
                  </p>
                ) : null}
                {_totalPrice > 0 ? (
                  <p>
                    Sub Total Amount{" "}
                    <span>
                      ₹
                      {isMembershipAdded
                        ? _totalPrice + membershipPrice
                        : _totalPrice}
                    </span>{" "}
                  </p>
                ) : null}
                {isMembershipAdded && membershipBenefit > 0 ? (
                  <p>
                    Membership Benefit <span>-₹{membershipBenefit}</span>{" "}
                  </p>
                ) : null}
                <p>
                  Total Amount
                  <span>
                    ₹{frameProtectionAdded ? totalPrice + 1 : totalPrice}
                  </span>{" "}
                  <h6
                    style={{
                      position: "absolute",
                      marginTop: "50px",
                      fontSize: "12px",
                    }}
                  >
                    (total tax incl.): ₹{" "}
                    <span className="ml-1">{`${totalTax}`}</span>₹
                  </h6>
                </p>
              </div>
              <div className="frame-protection">
                <h3>Also Add</h3>
                <div>
                  <div className="custom-control custom-checkbox">
                    <input
                      id="frame-protection"
                      type="checkbox"
                      className="custom-control-input"
                      checked={
                        sessionStorage.getItem("frameprotection") ||
                        frameProtectionAdded
                      }
                      value={
                        sessionStorage.getItem("frameprotection") ||
                        frameProtectionAdded
                      }
                      onClick={(e) => {
                        if (e.target.checked) {
                          sessionStorage.setItem("frameprotection", true);
                          setFramProtectionAdded(e.target.checked);
                        } else {
                          sessionStorage.removeItem("frameprotection");
                          setFramProtectionAdded(false);
                        }
                      }}
                    />
                    <label
                      htmlFor="frame-protection"
                      className="custom-control-label"
                    >
                      <img
                        className="frame-protection-image"
                        src={cartImage}
                        alt="frame-protection"
                      />
                      <div className="frame-protection-text">
                        <span>Chashma Planet Lens Protection Kit Just @1</span>
                      </div>
                    </label>
                  </div>
                </div>
              </div>
              {!isMembershipAdded && (
                <div className="frame-coupon">
                  <h3>Apply Coupon</h3>
                  <div className="frame-default-coupon">
                    {defaultCoupons.map((coupon) => (
                      <div
                        key={coupon.code}
                        className={
                          selectCoupon === coupon.code
                            ? "coupon-container active"
                            : "coupon-container"
                        }
                        onClick={onDefaultCouponClick.bind(null, coupon.code)}
                      >
                        <div className="coupon-content">
                          {/* <p>{coupon.title}</p> */}
                          <div className="coupon-tag">
                            <img src={coupon.image} alt="coupon-tag" />
                          </div>
                          {/* <span>{coupon.code}</span> */}
                        </div>
                      </div>
                    ))}
                  </div>
                  {/* <form
                  className="frame-coupon-form"
                  onSubmit={handleCouponSubmit}
                >
                  <div className="form-group">
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Enter Coupon Code"
                      name="couponCode"
                      required
                      value={couponCode}
                      onChange={(e) => setCouponCode(e.target.value)}
                    />
                    <button
                      type="submit"
                      className="btn btn-primary custom-btn"
                    >
                      Apply Coupon
                    </button>
                  </div>
                </form> */}
                </div>
              )}
            </>
          ) : null}
        </div>
      </div>
      <Login
        isOrderWithoutLogin={true}
        openLogin={openLogin}
        onLoginClose={() => setOpenLogin(false)}
      />
    </div>
  );
};

const mapStateToPros = (state) => ({
  saveAddress: state.users.saveUserAddress,
  userAdd: state.users.userAddress,
});

function mapDispatchToProps(dispatch) {
  return { dispatch };
}

export default withRouter(connect(mapStateToPros, mapDispatchToProps)(Cart));
