import * as React from "react";
import { useState, useEffect } from "react";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
// import image from '../../assets/images/amit.png'
import image from "../../assets/images/Ellipse 124.png";
import ellipse from "../../assets/images/Ellipse 123.png";
import ellipse2 from "../../assets/images/Ellipse 122.png";
import StarIcon from "@mui/icons-material/Star";
import Slider from "react-slick";
import { clientSays } from "../../utils/index";

const sliderProps = {
  arrows: false,
  infinite: true,
  speed: 500,
  className: "client-slider",
  loop: true,
  centerMode: true,
  autoplay: true,
  slidesToShow: 2,
  fade: false,
  autoplaySpeed: 2000,
  responsive: [
    {
      breakpoint: 1024,
      settings: {
        slidesToShow: 1,
      },
    },
    {
      breakpoint: 900,
      settings: {
        slidesToShow: 1,
      },
    },
    {
      breakpoint: 767,
      settings: {
        slidesToShow: 1,
      },
    },
  ],
};
const cardData = [
  {
    title: "Lizard 1",
    image: image,
    description: "Another description for the second lizard.",
  },
  {
    title: "Lizard 2",
    image: ellipse2,
    description: "Another description for the second lizard.",
  },
  {
    title: "Lizard 3",
    image: ellipse,
    description: "Another description for the second lizard.",
  },

  // Add more objects for additional cards as needed
];
// const sliderProps1 = {
//   slidesToShow: 3,
//   slidesToScroll: 1,
//   autoplay: true,
//   autoplaySpeed: 2000,
//   centerMode: false,
// };

const testimonialProps = {
  arrows: false,
  infinite: true,
  speed: 500,
  loop: true,
  autoplay: true,
  slidesToShow: 4,
  fade: false,
  adaptiveHeight: true,
  autoplaySpeed: 4000,
  responsive: [
    {
      breakpoint: 1024,
      settings: {
        slidesToShow: 3,
      },
    },
    {
      breakpoint: 991,
      settings: {
        slidesToShow: 2,
      },
    },
    {
      breakpoint: 767,
      settings: {
        slidesToShow: 1,
      },
    },
  ],
};

export default function MediaCardClient() {
  const [clientData, setClientData] = useState([]);
  const fetchWhatClientSays = async () => {
    const { data, error } = await clientSays();
    setClientData([...data.data]);
  };
  useEffect(() => {
    fetchWhatClientSays();
  }, []);

  return (
    <>
      <Slider {...sliderProps}>
        {clientData.length > 0 &&
          clientData.map((data) => (
            <div className="b-radius ">
              <Card key={data._id} sx={{ maxWidth: 345 }}>
                <CardMedia
                  className="aaaa"
                  sx={{ height: 140 }}
                  image={data.image}
                  // title={data.title}
                />
                <CardContent>
                  <Typography
                    className="mb-0 p-font mt-2"
                    gutterBottom
                    variant="h2"
                    component="p"
                  >
                    {data.name}
                  </Typography>
                  <Typography
                    className="mb-0 p-font mt-2"
                    gutterBottom
                    variant="h6"
                    component="p"
                  >
                    {data.description}
                  </Typography>
                </CardContent>

                <Typography>
                  <p>{data.designation}</p>
                </Typography>
              </Card>
            </div>
          ))}
      </Slider>

      {/* <Slider {...sliderProps1}>
        {clientData.length > 0 &&
          clientData.map((data) => (
            <div className="b-radius ">
              <Card key={data._id} sx={{ maxWidth: 345 }}>
                <CardMedia
                  className="aaaa"
                  sx={{ height: 140 }}
                  image={data.image}
                  // title={data.title}
                />
                <CardContent>
                  <Typography
                    className="mb-0 p-font mt-2"
                    gutterBottom
                    variant="h2"
                    component="p"
                  >
                    {data.name}
                  </Typography>
                  <Typography
                    className="mb-0 p-font mt-2"
                    gutterBottom
                    variant="h6"
                    component="p"
                  >
                    {data.description}
                  </Typography>
                </CardContent>

                <Typography>
                  <p>{data.designation}</p>
                </Typography>
              </Card>
            </div>
          ))}
      </Slider> */}
      {/* <Slider {...sliderProps}>

      {cardData.map((card, index) => (
        <div className='b-radius ' >
          <Card key={index} sx={{ maxWidth: 345 }} >
            <CardMedia
            className='aaaa'
              sx={{ height: 140 }}
              image={card.image}
              title={card.title}
            />
            <CardContent >
              <Typography >
                <StarIcon className='icon-clr' />
                <StarIcon className='icon-clr' />
                <StarIcon className='icon-clr' />
                <StarIcon className='icon-clr' />
                <StarIcon className='icon-clr' />
              </Typography>

              <Typography className='mb-0 p-font mt-2' gutterBottom variant="h6" component="p">
                “Great Price, Great Quality, Great Styles, Great Customer Service. First-Grade Products With Affordable Prices. I’ve Already Recommended Chasmaplanet To Three Friends.”
              </Typography>

             
            </CardContent>
            <Typography
              variant='h5'
            >
              Yash Mathur
            </Typography>
            <Typography>
              <p>Businessman, Noida</p>
            </Typography>
          </Card>
        </div>
      ))}
      </Slider> */}
    </>
  );
}
