import React from "react";
import { useDispatch } from "react-redux";
import {
  getSuccessNotificationMessage,
  getErrorNotificationMessage,
} from "../helper/NotificationMessage";
import { createNotification } from "react-redux-notify";
import { newLetterSubmit } from "../../utils/index";
import { useState } from "react";

const Newsletter = () => {
  const dispatch = useDispatch();
  const [newsletterInputData, setNewsletterInputData] = useState({
    email: "",
  });
  const [isDisable, setIsDisable] = useState(false);

  const handleNewsletterInputDataChange = (e) => {
    setNewsletterInputData((data) => ({
      ...data,
      [e.target.name]: e.target.value,
    }));
  };

  const handleSubmit = async (e) => {
    e && e.preventDefault();
    setIsDisable(true);
    let message;
    if (newsletterInputData.email) {
      const { data, error } = await newLetterSubmit({ email: newsletterInputData.email });
      if (data && data.success) {
        setIsDisable(false);
        message = getSuccessNotificationMessage(data.msg);
        dispatch(createNotification(message));
      } else {
        message = getErrorNotificationMessage(data?.errors.email[0]|| error?.errors.email[0]);
        // message = getErrorNotificationMessage(data?.msg || error?.msg);
        dispatch(createNotification(message));
      }
      setNewsletterInputData({ email: "" });
    }
  };

  return (
    <div className="newsletter-subscription">
      <h4>Our Newsletter</h4>
      <span>
        Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod
        tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim
        veniam,
      </span>
      <form onSubmit={handleSubmit}>
        <input
          type="email"
          required
          name="email"
          placeholder="EMAIL"
          onChange={handleNewsletterInputDataChange}
        />
        <button type="submit" disabled={isDisable} className="btn btn-primary custom-btn">
          SUBSCRIBE
        </button>
      </form>
    </div>
  );
};

export default Newsletter;
