import React, { useState, useEffect } from "react";
import { Modal } from "react-bootstrap";
import { connect, useDispatch } from "react-redux";
import { useHistory } from "react-router";
import axios from 'axios';
import { createNotification } from "react-redux-notify";
import {
  userLoginStart,
  userRegistrationStart,
  socialLoginStart,
} from "../../store/actions/UserAction";
import logo from "../../assets/images/new-logo.svg";
import whatsapp from "../../assets/images/whatsapp-icon.svg";
import PasswordReset from "../PasswordReset/PasswordReset";
// import facebook from "../../assets/images/facebook.svg";
// import FacebookLogin from "react-facebook-login/dist/facebook-login-render-props";
import { GoogleLogin } from '@react-oauth/google';
import google from '../../assets/images/search.png'
import CloseIcon from '@mui/icons-material/Close';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import {
  getErrorNotificationMessage,
  getSuccessNotificationMessage,
} from "../helper/NotificationMessage";
// import OtpInput from "react-otp-input";

const Login = (props) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { openLogin, onLoginClose, isOrderWithoutLogin = false,
    isTracking = false, trackUrl = '' } = props;
  const [loginInputData, setLoginInputData] = useState({});
  const [signupInputData, setSignupInputData] = useState({ password1: "" });
  const [orderWlInputData, setOrderWlInputData] = useState({ phoneNumber: "" });
  const [otpVerficationInputData, setOtpVerificationInputData] = useState({
    otp: "",
  });
  const [otpVerificationOpen, setOtpVerificationOpen] = useState(false);
  const [openPasswordReset, setOpenPasswordReset] = useState(false);

  const handleLoginInputChange = (event) => {
    const { value, name } = event.currentTarget;
    setLoginInputData((prevState) => ({ ...prevState, [name]: value }));
  };

  const handleSignupInputChange = (event) => {
    const { value, name } = event.currentTarget;
    setSignupInputData((prevState) => ({ ...prevState, [name]: value }));
  };

  const handleOrderWlIInputChange = (event) => {
    const { value, name } = event.currentTarget;
    setOrderWlInputData((prevState) => ({ ...prevState, [name]: value }));
  };

  const handleLogin = async (event) => {
    event && event.preventDefault();
    await props.dispatch(userLoginStart({ ...loginInputData }));
    if (isTracking) {
      history.push(trackUrl);
    }
  };

  const handleOrderWithoutLogin = async (event) => {
    event && event.preventDefault();
    const response = await axios.post(`${process.env.REACT_APP_API_URL}user/create/guest`, { ...orderWlInputData })
    if (response.data?.success) {
      const notificationMessage = getSuccessNotificationMessage(
        'Login Successfully'
      );
      dispatch(createNotification(notificationMessage));
      sessionStorage.setItem("access", response.data.token);
      sessionStorage.setItem("refresh_token", response.data.refreshToken);
      sessionStorage.setItem("user_id", response.data.data._id);
      onLoginClose(true);
      window.location.reload();
    } else {
      const notificationMessage = getErrorNotificationMessage(
        'Something went wrong'
      );
      dispatch(createNotification(notificationMessage));
    }

    // setOtpVerificationOpen(true);
  };
  const handleOptVerification = async (event) => {
    event && event.preventDefault();
    // Handle otp verification

    setOtpVerificationOpen(true);
  };

  useEffect(() => {
    if (props.loginInputData?.isSuccess) {
      onLoginClose(true);
    }
    if (props.registerInputData?.isSuccess) {
      onLoginClose(true);
    }
  }, [props.loginInputData?.isSuccess || props.registerInputData?.isSuccess]);

  const getAuthStatus = () => {
    return new Promise((resolve) => {
      window.FB.getLoginStatus(function (response) {
        resolve(response);
      }, true);
    });
  };

  const getUser = (fields = "email,name,id,first_name,last_name,picture") => {
    return new Promise((resolve) => {
      window.FB.api("/me", "GET", { fields }, resolve);
    });
  };

  const handleSignup = (event) => {
    event && event.preventDefault();
    props.dispatch(
      userRegistrationStart({
        ...signupInputData,
        password1: signupInputData.password,
      })
    );
  };

  // const responseFacebook = async (response) => {
    
  //   const auth = await getAuthStatus();

    
  //   if (auth?.status === "connected") {
  //     if (response?.accessToken) {
  //       const { authResponse } = auth;
  //       const _profile = await getUser();
        
  //       props.dispatch(
  //         socialLoginStart({
  //           token: authResponse.accessToken,
  //           email: _profile.email,
  //           firstName: _profile.first_name,
  //           LastName: _profile.last_name,
  //           profile: _profile?.picture?.data?.url || "",
  //         })
  //       );

  //       // sessionStorage.setItem("name", _profile.first_name + " " + _profile.last_name);
  //     }
  //   }
  // };

  const responseGoogle = async (response) => {    
    if (response?.tokenId) {
      props.dispatch(
        socialLoginStart({
          token: response.tokenId,
          email: response.profileObj.email,
          firstName: response.profileObj.givenName,
          LastName: response.profileObj.familyName,
          profile: response.profileObj.imageUrl,
        })
      );
    }
  };

  

  return (
    <>
      <Modal
        className="custom-dialog login-dialog"
        size="xl"
        centered
        show={openLogin}
        backdrop={isTracking ? "static" : ''}
        onHide={() => {
          setLoginInputData({ email: "", password: "" });
          setSignupInputData({
            firstName: "",
            LastName: "",
            phone: "",
            email: "",
            password: "",
          });
          onLoginClose(false);
        }}
      >
        <div className="modal-content">
          <div className="modal-body">
            <div className="row m-0">
              <div className="col-md-5 p-0">
                <div className="modal-left">
                  <div className="logo-wrap">
                    <a href="/">
                    <h4 style={{color:'white'}}>Chashma Planet</h4>
                      {/* <img
                        src={logo}
                        alt="Framskraft.com"
                        title="Framskraft.com"
                      /> */}
                    </a>
                  </div>
                  <div className="text-wrap">
                    <a href="https://api.whatsapp.com/send/?phone=919859424242&text&app_absent=0">
                      <h2>
                        Get Expert Help Now Available On{" "}
                        <span>
                          <img src={whatsapp} alt="" />
                          Whatsapp{" "}
                        </span>
                      </h2>
                    </a>
                    <h3>Elite Membership Programme</h3>
                    <p>Get Special Offers And Benifits</p>
                    <a
                      href="/elite-membership"
                      alt="KNOW MORE"
                      title="KNOW MORE"
                    >
                      KNOW MORE <sapn><ArrowForwardIosIcon/></sapn>
                    </a>
                  </div>
                </div>
              </div>
              <div className="col-md-7 p-0 login-cross">
                {!isTracking &&
                  <i
                  onClick={() => {
                    setLoginInputData({ email: "", password: "" });
                    setSignupInputData({
                      firstName: "",
                      LastName: "",
                      phone: "",
                      email: "",
                      password: "",
                    });
                    onLoginClose(false);
                  }}
                  style={{ float: "right", cursor: "pointer" }}
                  >
                  <CloseIcon/> 
                  </i>
                }
                <div className="modal-right">
                  <ul className="nav nav-tabs" id="login-tab" role="tablist">
                    <li className="nav-item">
                      <a
                        className="head-text active"
                        id="home-tab"
                        data-toggle="tab"
                        href="#home"
                        role="tab"
                        aria-controls="home"
                        aria-selected="true"
                        alt="Login"
                        title="Login"
                      >
                        LOGIN
                      </a>
                    </li>
                    {!isTracking &&
                    <li className="nav-item">
                      <a
                        id="profile-tab"
                        data-toggle="tab"
                        href="#profile"
                        role="tab"
                        aria-controls="profile"
                        aria-selected="false"
                        alt="Sign Up"
                        title="Sign Up"
                      >
                        SIGN UP
                      </a>
                    </li> }
                    {isOrderWithoutLogin && (
                      <li className="nav-item">
                        <a
                          id="order-without-login-tab"
                          data-toggle="tab"
                          href="#order-without-login"
                          role="tab"
                          aria-controls="order-without-login"
                          aria-selected="false"
                          alt="Order Without Login"
                          title="Order Without Login"
                        >
                          Order Without Login
                        </a>
                      </li>
                    )}
                  </ul>
                  <div className="tab-content" id="logintabcontent">
                    <div
                      className="tab-pane fade show active"
                      id="home"
                      role="tabpanel"
                      aria-labelledby="home-tab"
                    >
                      <div className="custom-form-wrapper">
                        <form className="login-form" onSubmit={handleLogin}>
                          <div className="form-group">
                            <input
                              type="email"
                              className="form-control"
                              placeholder="Email"
                              name="email"
                              value={loginInputData.email}
                              onChange={handleLoginInputChange}
                              required
                            />
                          </div>
                          <div className="form-group">
                            <input
                              type="password"
                              className="form-control"
                              placeholder="Password"
                              name="password"
                              value={loginInputData.password}
                              onChange={handleLoginInputChange}
                              required
                            />
                          </div>
                          <div className="form-check">
                            {!isTracking &&
                                <a
                                role="tab"
                                aria-controls="password-reset"
                                aria-selected="false"
                                id="passwordreset-tab"
                                data-toggle="tab"
                                href="#reset-dialog"
                                alt="Forgot Password"
                                title="Forgot Password"
                                onClick={() => {
                                  onLoginClose();
                                  setOpenPasswordReset(true);
                                }}
                              >
                                Forgot Password?
                              </a>
                            }
                          </div>
                          <button
                            type="submit"
                            className="btn btn-primary custom-btn"
                          >
                            Login
                          </button>
                        </form>
                      </div>
                    </div>
                    <div
                      className="tab-pane fade"
                      id="profile"
                      role="tabpanel"
                      aria-labelledby="profile-tab"
                    >
                      <div className="custom-form-wrapper">
                        <div className="social-block text-center">
                          <p style={{ color: "black" }}>Sign up with</p>
                          <div className="other-login">
                            {/* <FacebookLogin
                              // appId="466503142813519"
                              // appscrate="8925d74547915d731940d6a2d67aea72"
                              appId="500062945843494"
                              // appId="399539256036753"
                              callback={responseFacebook}
                              render={(renderProps) => (
                                <a
                                  onClick={renderProps.onClick}
                                  title="Facebook"
                                  alt="Facebook"
                                >
                                  <img src={facebook} alt="facebook" />
                                </a>
                              )}
                            /> */}
                            
                            {/* <GoogleLogin */}
                            <div className="nitin">
                            <img src={google} alt="google" className="img-google" />
                            <GoogleLogin 
                            clientId="433477656145-73ghf36c00vkbkonb0jnt7me3a3i2j10.apps.googleusercontent.com"
                            render={(renderProps) => (
                              <a
                                onClick={renderProps.onClick}
                                disabled={renderProps.disabled}
                                title="Gmail"
                                alt="Gmail"
                              >
                                <img src={google} alt="google" />
                              </a>
                            )}
                            onSuccess={responseGoogle} onError={responseGoogle} />
                            </div>
                          </div>
                        </div>
                        <form className="signup-form" onSubmit={handleSignup}>
                          <div className="form-group">
                            <input
                              type="text"
                              className="form-control"
                              id="firstname"
                              placeholder="First Name"
                              name="firstName"
                              required
                              value={signupInputData.firstName}
                              onChange={handleSignupInputChange}
                            />
                            <input
                              type="text"
                              className="form-control"
                              id="lastname"
                              placeholder="Last Name"
                              name="LastName"
                              required
                              value={signupInputData.LastName}
                              onChange={handleSignupInputChange}
                            />
                          </div>
                          <div className="form-group">
                            <input
                              type="text"
                              className="form-control"
                              id="mobile"
                              placeholder="Mobile number"
                              name="phone"
                              maxLength="10"
                              pattern="\d{10}"
                              title="Please enter exactly 10 digits"
                              required
                              value={signupInputData.phone}
                              onChange={handleSignupInputChange}
                            />
                          </div>
                          <div className="form-group">
                            <input
                              type="email"
                              className="form-control"
                              placeholder="Email Address"
                              name="email"
                              required
                              value={signupInputData.email}
                              onChange={handleSignupInputChange}
                            />
                          </div>
                          <div className="form-group">
                            <input
                              type="password"
                              className="form-control"
                              placeholder="Password"
                              name="password"
                              required
                              value={signupInputData.password}
                              onChange={handleSignupInputChange}
                            />
                          </div>

                          <button
                            type="submit"
                            className="btn btn-primary custom-btn"
                          >
                            Register
                          </button>
                        </form>
                      </div>
                    </div>
                    <div
                      className="tab-pane fade show"
                      id="order-without-login"
                      role="tabpanel"
                      aria-labelledby="order-without-login-tab"
                    >
                      <div className="custom-form-wrapper">
                        {/* {!otpVerificationOpen ? ( */}
                          <form
                            className="login-form"
                            onSubmit={handleOrderWithoutLogin}
                          >
                            <div className="form-group order-without-number">
                              <input
                                type="text"
                                className="form-control"
                                placeholder="Mobile Number"
                                maxLength="10"
                                pattern="\d{10}"
                                title="Please enter exactly 10 digits"
                                name="phoneNumber"
                                value={orderWlInputData.phoneNumber}
                                onChange={handleOrderWlIInputChange}
                                required
                              />
                            </div>
                            <button
                              type="submit"
                              className="btn btn-primary custom-btn"
                            >
                              Order Now
                            </button>
                          </form>
                        {/* ) : ( */}
                          {/* <form
                            className="login-form"
                            onSubmit={handleOptVerification}
                          >
                            <div className="form-group otp-verfication-container">
                              <h5>Enter OTP</h5>
                              <OtpInput
                                value={otpVerficationInputData.otp}
                                onChange={(otp) =>
                                  setOtpVerificationInputData({ otp })
                                }
                                numInputs={4}
                                className="mx-2 otp-verification"
                              />
                              <button className="send-otp-again">
                                Send otp again
                              </button>
                            </div>
                            <button
                              type="submit"
                              className="btn btn-primary custom-btn"
                            >
                              Get OTP
                            </button>
                          </form> */}
                        {/* )} */}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Modal>
      <PasswordReset
        openPasswordReset={openPasswordReset}
        closePasswordReset={() => setOpenPasswordReset(false)}
      />
    </>
  );
};

const mapStateToPros = (state) => ({
  loginInputData: state.users.loginInputData,
  registerInputData: state.users.registerInputData,
});

function mapDispatchToProps(dispatch) {
  return { dispatch };
}

export default connect(mapStateToPros, mapDispatchToProps)(Login);
